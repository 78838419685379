<template>
<div style="margin-top: 20px;text-align: center;">
	<div class="sameWidth">
		<el-breadcrumb separator-class="el-icon-arrow-right" style="padding:20px 0 0 0;">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>公司业务</el-breadcrumb-item>
		</el-breadcrumb>
		<div style="padding: 20px 0px;">
			<div style="display: flex;">
				<div style="flex: 1;padding: 20px;" v-for="(item,index) in yewu.slice(0,3)" :key="index" class="hoverDiv">
					<img :src="item.src" style="width:340px;height: 230px;"/>
					<div style="padding: 14px;cursor: pointer;" @click="goTo(item.url)">
						<h2>{{ item.title }}</h2>
						<p style="text-align: left;font-size: 16px;color: #595959;line-height: 25px;">{{ item.desp }}</p>
					</div>
				</div>
			</div>
			<div style="display: flex;">
				<div style="flex: 1;padding: 20px;" v-for="(item,index) in yewu.slice(3,5)" :key="index" class="hoverDiv">
					<img :src="item.src" class="image" style="width:100%;"/>
					<div style="padding: 14px;cursor: pointer;" @click="goTo(item.url)">
						<h2>{{ item.title }}</h2>
						<p style="text-align: left;font-size: 16px;color: #595959;line-height: 25px;">{{ item.desp }}</p>
					</div>
				</div>
				<div style="flex: 1;padding: 20px;"></div>
			</div>
		</div>
	</div>
	
	
</div>
</template>
<style scoped>
	*{font-family:'Microsoft YaHei';}
</style>
<script>
export default {
  data() {
    return {
		paddingRow:0,
      yewu: [
        {
			id: 1,
			url:'/tenderAgent',
			src: require("../../../assets/images/companyActive/公司业务—代理.jpg"),
			title: "招标代理",
			desp: "招投研究院具有独立编制有关招标文件、有效组织评标活动的专业团队和技术设施，具有多年从事招标代理业务的经验，不但为招标人提供合法合规、专业高效的服务，而且能为招标人解决很多招标采购中的棘手问题。",
        },
        {
			id: 2,
			url:'/tenderSultation',
			src: require("../../../assets/images/companyActive/公司业务—招投标咨询.jpg"),
			title: "招投标咨询",
			desp: "招投研究院具有专业的招投标咨询专家团队。他们具有丰富的招投标理论知识和实践经验，应用科学、前沿的方法，找出企业在招投标管理方面存在的问题，分析原因，提出改进方案，并指导企业将改进方案实施落地，改善企业的招投标管理水平。",
        },
        {
			id: 3,
			url:'/tenderTrain',
			src: require("../../../assets/images/companyActive/公司业务—培训.jpg"),
			title: "招投标培训",
			desp: "招投标培训服务是为企事业单位提供各种招标投标培训课程，旨在帮助客户规范招投标管理、降低招投标风险、提高招投标效率、提高成功概率。培训服务分为公益课、公开课、企业内训课。",
        },
        {
			id: 4,
			url:'/tenderTotal',
			src: require("../../../assets/images/companyActive/公司业务—审计.jpg"),
			title: "招标审计",
			desp: "招投研究院具有专业的招标审计团队，通过对招投标制度和流程、招投标实施过程、招标方案及相关文件进行审查，从合法性、规范性层面提出整改建议。",
        },
        {
			id: 5,
			url:'/tenderResearch',
			src: require("../../../assets/images/companyActive/公司业务—研究.jpg"),
			title: "招投标专题研究",
			desp: "目前承接的招投标专题研究业务有智能辅助评标、投标人画像、围标串标等，通过专注某个专题的研究，为客户提供更深层次的专业解决方案，提升客户招投标管理能力与效率，降低招投标管理风险。",
        },
      ],
    };
  },
	methods:{
		goTo(res){
			this.$router.push(res);
		}
	},
	mounted:function(){
		this.bus.$emit('headerImg',[{src:require('@/assets/images/companyActive/介绍.png'),href:""}]);
	}
};
</script>
<style scoped>
	.hoverDiv{
		transition:All 0.4s ease-in-out;
		-webkit-transition:All 0.4s ease-in-out;
		-moz-transition:All 0.4s ease-in-out;
		-o-transition:All 0.4s ease-in-out;
	}
	.hoverDiv:hover {
		transform:scale(1.05);
		-webkit-transform:scale(1.05);
		-moz-transform:scale(1.05);
		-o-transform:scale(1.05);
		-ms-transform:scale(1.05);
	}
</style>
